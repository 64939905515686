<template>
  <div class="mspk">
    <link
      href="https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap"
      rel="stylesheet"
    />

    <div class="mspk__first-banner">
      <div class="content">
        <div class="mspk__first-banner-img">
          <div class="mspk__first-banner-title">
            Диагностика <br /><span
              >методом сухого <br />
              пятна крови<sup>1-11</sup></span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="mspk__about">
      <div class="content">
        <div class="mspk__about-row">
          <div class="mspk__about-tag button">О методе</div>
          <div class="mspk__about-text">
            Метод сухого пятна крови (DBS - dry blood spot) предназначен для
            скрининга редких генетических заболеваний, он прост и эффективен для
            постановки диагноза и используется для многих современных
            лабораторных тестов.
          </div>
        </div>
      </div>
    </div>
    <div class="mspk__instruction">
      <div class="content">
        <div class="mspk__instruction-row">
          <div :data-coolbox="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/mspk/video.mp4`" class="mspk__instruction-video">
            <div class="mspk__instruction-video-img">
              <img
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/mspk/instruction-video.png 2x`"
              />
            </div>
            <div class="mspk__instruction-video-title">
              Пошаговая видеоинструкция
            </div>
          </div>
          <div class="mspk__instruction-block">
            <div class="mspk__instruction-block-title">
              Инструкция <br />по применению
            </div>
            <a :href="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/mspk/instruction.pdf`" target="_blank" class="mspk__instruction-block-button button">
              Читать инструкцию
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="mspk__description">
      <div class="content">
        <div class="mspk__description-caption-row">
          <div class="mspk__description-title">
            На чём основан метод сухого пятна крови?
          </div>
          <div class="mspk__description-description">
            На капельном нанесении крови на специальную впитывающую мембрану на
            карте-фильтре с последующим высушиванием пробы биологического
            материала. Карта-фильтр используется для забора и транспортировки
            биологического материала в лабораторию для анализа.
          </div>
        </div>
        <div class="mspk__description-row">
          <div class="mspk__description-text">
            <div class="mspk__description-text-title">
              Описание карты-фильтра для забора крови:
            </div>
            <div class="mspk__description-text-description">
              <p class="mb-4">
                Для приготовления DBS-карты стандартно используется карта-фильтр
                №903 (Whatman) или карта-фильтр аналогичная по своим свойствам.
              </p>
              <p class="mb-4">
                На внутренней части карты-фильтра очерчены круги, которые
                обозначают места нанесения капель крови.
              </p>
              <p>
                При нанесении капель крови в центры кругов на карте-фильтре, они
                пропитывают целлюлозный фильтр и удерживаются на нем при
                высушивании.
              </p>
            </div>
          </div>
          <div class="mspk__description-img">
            <picture>
              <source
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/mspk/description2_mob.png`"
                media="(max-width: 1220px)"
              />
              <source
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/mspk/description2.png`"
                media="(min-width: 1221px)"
              />
              <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/mspk/description2.png`" />
            </picture>
          </div>
        </div>
      </div>
    </div>
    <div class="mspk__methods">
      <div class="content">
        <div class="mspk__methods-caption-row">
          <div class="mspk__methods-title">
            Способы забора биоматериала для получения сухого пятна крови
          </div>
          <div class="mspk__methods-description">
            Медицинский персонал должен соблюдать правила стерильности, протирая
            место прокола раствором антисептика, используя одноразовые
            иглы-скарификаторы и перчатки. Для лучшего притока крови к месту
            прокола предварительно проводят растирание.
          </div>
        </div>
        <div class="mspk__methods-row-title">
          Кровь пациента по каплям наносят в центр окружности, соблюдая правило:
          <br /><span>одна капля — одна окружность</span>
        </div>
        <div class="mspk__methods-row">
          <div
            class="mspk__methods-row-item"
            :style="`background-image: url(https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/mspk/methods-back1.jpg)`"
          >
            <div class="mspk__methods-row-item-head">
              <div class="mspk__methods-row-item-number">
                <img
                  :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/mspk/methods-digit1.png 2x`"
                />
              </div>
            </div>
            <div class="mspk__methods-row-item-text">
              Кровь для исследования берется из <b>пальца, пятки или вены</b>.
              При заборе венозной крови необходимо использовать пробирку с ЭДТА.
              Важно, чтобы пятно на фильтре было образовано пропитыванием от
              одной капли крови и пропитывание фильтра было достаточным
            </div>
          </div>
          <div
            class="mspk__methods-row-item"
            :style="`background-image: url(https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/mspk/methods-back2.jpg)`"
          >
            <div class="mspk__methods-row-item-head">
              <div class="mspk__methods-row-item-number">
                <img
                  :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/mspk/methods-digit2.png 2x`"
                />
              </div>
            </div>
            <div class="mspk__methods-row-item-text">
              Размер насквозь пропитанного кровью кружка должен быть
              <b>не менее 75%</b> площади очерченного круга
            </div>
          </div>
          <div
            class="mspk__methods-row-item"
            :style="`background-image: url(https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/mspk/methods-back3.jpg)`"
          >
            <div class="mspk__methods-row-item-head">
              <div class="mspk__methods-row-item-number">
                <img
                  :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/mspk/methods-digit3.png 2x`"
                />
              </div>
            </div>
            <div class="mspk__methods-row-item-text">
              Количество кружков с кровью <br /><b>– не менее 4-х</b>
            </div>
          </div>
          <div
            class="mspk__methods-row-item"
            :style="`background-image: url(https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/mspk/methods-back4.jpg)`"
          >
            <div class="mspk__methods-row-item-head">
              <div class="mspk__methods-row-item-number">
                <img
                  :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/mspk/methods-digit4.png 2x`"
                />
              </div>
              <div class="mspk__methods-row-item-img">
                <picture>
                  <source
                    :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/mspk/methods-img4_mob.png 2x`"
                    media="(max-width: 1220px)"
                  />
                  <source
                    :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/mspk/methods-img4.png 2x`"
                    media="(min-width: 1221px)"
                  />
                  <img
                    :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/mspk/methods-img4.png 2x`"
                  />
                </picture>
              </div>
            </div>
            <div class="mspk__methods-row-item-text">
              Каплю крови нужно наносить 1 раз в центр круга, при этом нельзя
              наносить каплю в один круг дважды. Отклонение от центра приводит
              часто к необходимости повторного нанесения капли на ту же область,
              что вызывает локальное увеличение концентрации вещества на
              носителе и, как следствие, завышение результатов анализа, а также
              снижение воспроизводимости результатов.
            </div>
          </div>
          <div
            class="mspk__methods-row-item"
            :style="`background-image: url(https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/mspk/methods-back5.jpg)`"
          >
            <div class="mspk__methods-row-item-head">
              <div class="mspk__methods-row-item-number">
                <img
                  :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/mspk/methods-digit5.png 2x`"
                />
              </div>
              <div class="mspk__methods-row-item-img">
                <img
                  :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/mspk/methods-img5.png 2x`"
                />
              </div>
            </div>
            <div class="mspk__methods-row-item-text">
              Также капля крови не должна выходить <br />за пределы круга.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mspk__diseases">
      <div class="content">
        <div class="mspk__diseases-title">
          Методом сухого пятна можно провести <br />диагностику таких
          заболеваний, как:
        </div>
        <div class="mspk__diseases-row">
          <div class="mspk__diseases-item">
            <div class="mspk__diseases-item-img">
              <img
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/mspk/diseases-img1.png 2x`"
              />
            </div>
            <div class="mspk__diseases-item-title">Гипофосфатазия </div>
            <div class="mspk__diseases-item-description">
              Прогрессирующее наследственное метаболическое заболевание,
              вызванное дефицитом щелочной фосфатазы (ЩФ) в результате мутации
              гена ALPL. Дефицит активности ЩФ проводит к нарушению
              минерализации костей, аномалиям скелета и другим системным
              осложнениям.
            </div>
          </div>
          <div class="mspk__diseases-item">
            <div class="mspk__diseases-item-img">
              <img
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/mspk/diseases-img2.png 2x`"
              />
            </div>
            <div class="mspk__diseases-item-title">
              Дефицит лизосомной кислой липазы
            </div>
            <div class="mspk__diseases-item-description">
              Прогрессирующее наследственное метаболическое заболевание,
              вызванное дифицитом лизосомной кислой липазы (ЛКЛ) в результате
              мутации гена LIPA. Дефицит ЛКЛ приводит к нарушению обменных
              процессов в печени и желудочно-кишечном тракте, нарушению
              липидного профиля, и мультисистемному поражению органов.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mspk__order">
      <div class="content">
        <div class="mspk__order-title">
          Порядок действий для отправки образца пациента <br />на диагностику
          методом сухого пятна крови
        </div>
        <div class="mspk__order-row">
          <div class="mspk__order-col">
            <div class="mspk__order-item">
              <div class="mspk__order-item-head">
                <div class="mspk__order-item-number">
                  <img
                    :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/mspk/order-digit1.png 2x`"
                  />
                </div>
                <div class="mspk__order-item-title">
                  Позвонить на горячую линию
                </div>
              </div>
              <div class="mspk__order-item-text">
                Специальные карты-фильтры и направлений на исследование и
                информированных согласий необходимо заказать обратившись по
                телефону горячей линии
                <a href="tel:88003010651" class="mspk__order-item-text-number"
                  >8&#8209;800&#8209;301&#8209;06&#8209;51</a
                >
                или с помощью
                <a href="#" class="mspk__order-item-text-link"
                  >онлайн&#8209;формы</a
                >.
              </div>
            </div>
            <div class="mspk__order-item">
              <div class="mspk__order-item-head">
                <div class="mspk__order-item-number">
                  <img
                    :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/mspk/order-digit2.png 2x`"
                  />
                </div>
                <div class="mspk__order-item-title">
                  Зарегистрироваться в программе
                </div>
              </div>
              <div class="mspk__order-item-text">
                Подпишите информированное согласие в
                <b>2-х экземплярах:</b> один экземпляр остается у врача, а
                второй отправляется вместе с сухим пятном крови. Подготовьте
                карты-фильтры.
              </div>
            </div>
            <div class="mspk__order-item">
              <div class="mspk__order-item-head">
                <div class="mspk__order-item-number">
                  <img
                    :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/mspk/order-digit3.png 2x`"
                  />
                </div>
                <div class="mspk__order-item-title">
                  Воспользоваться фильтр-картой для забора крови
                </div>
              </div>
              <div class="mspk__order-item-text">
                <p class="mb-4">
                  Заполните разборчиво все поля карты шариковой ручкой. ФИО
                  врача и пациента указываются полностью. Требуется
                  <b>5 капель капиллярной крови</b> для пяти кружков на
                  карте-фильтре от каждого пациента.
                </p>
                <p class="mb-4">
                  Неправильное нанесение может привести к ложно-отрицательным
                  результатам или признанию образца непригодным к исследованию.
                </p>
                <img
                  :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/mspk/order-img.png 2x`"
                />
              </div>
            </div>
          </div>
          <div class="mspk__order-col">
            <div class="mspk__order-item">
              <div class="mspk__order-item-head">
                <div class="mspk__order-item-number">
                  <img
                    :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/mspk/order-digit4.png 2x`"
                  />
                </div>
                <div class="mspk__order-item-title">
                  Высушить образцы 4 часа
                </div>
              </div>
              <div class="mspk__order-item-text">
                Для сохранения активности ферментов образцы
                <b>высушивают 4 часа при комнатной температуре</b>, вдали от
                источников высоких температур и солнечного света. Кружки крови
                во время высушивания не должны соприкасаться ни с какими
                поверхностями.
              </div>
            </div>
            <div class="mspk__order-item">
              <div class="mspk__order-item-head">
                <div class="mspk__order-item-number">
                  <img
                    :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/mspk/order-digit5.png 2x`"
                  />
                </div>
                <div class="mspk__order-item-title">Упаковать образцы</div>
              </div>
              <div class="mspk__order-item-text">
                После высушивания кружки крови следует накрыть откидным
                клапаном. Образец крови на карте-фильтре вложить в зип-пакет с
                осушителем, не вынимая осушитель, и плотно закрыть зиппер.
              </div>
            </div>
            <div class="mspk__order-item">
              <div class="mspk__order-item-head">
                <div class="mspk__order-item-number">
                  <img
                    :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/mspk/order-digit6.png 2x`"
                  />
                </div>
                <div class="mspk__order-item-title">
                  Отправить образцы на анализ 
                </div>
              </div>
              <div class="mspk__order-item-text">
                Для вызова курьера и отправки сухих пятен крови в лабораторию
                просто обратитесь по телефону горячей линии
                <a href="tel:88003010651" class="mspk__order-item-text-number"
                  >8&#8209;800&#8209;301&#8209;06&#8209;51</a
                >
                или заполните заявку <b>онлайн</b>.
              </div>
            </div>
            <div class="mspk__order-item">
              <div class="mspk__order-item-head">
                <div class="mspk__order-item-number">
                  <img
                    :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/mspk/order-digit7.png 2x`"
                  />
                </div>
                <div class="mspk__order-item-title">Получить результаты</div>
              </div>
              <div class="mspk__order-item-text">
                Результаты исследований будут вам высланы на электронный адрес,
                который указан на карте-фильтре. Срок получения результатов:
                <b>25-45</b> рабочих дней.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mspk__sources">
      <div class="content">
        <div class="mspk__sources-block">
          <div class="mspk__sources-title">Список сокращений</div>
          <div class="mspk__sources-text mb-6">
            DBS — Dried Blood Spots, метод сухого пятна крови;
            <br />
            ЭДТА — этилендиаминтетрауксусная кислота;
          </div>
          <div class="mspk__sources-title">Источники</div>
          <div class="mspk__sources-text">
            <ol>
              <li>
                Guthrie R., Ada S. A simple phenylalanine method for detecting
                phenylketonuria in lage populations of newborn infants. Pediatr.
                32, 1963. Vol. 32, № 3. P. 338–343.
              </li>
              <li>
                Meesters R. J., Hooff G.P. State-of-the-art dried blood spot
                analysis: an overview of recent advances and future trends.
                Bioanalysis, 2013. Vol. 5, № 17. P. 2187–2208.
              </li>
              <li>
                Demirev P. A. Dried blood spots: analysis and applications. Anal
                Chem, 2013. Vol. 85, № 2. P. 779–789.
              </li>
              <li>
                Sharma A., Jaiswal S., Shukla M., Lal J. Dried blood spots:
                concepts, present status, and future perspectives in
                bioanalysis. Drug Test. Anal, 2014. Vol. 6, № 5. P. 399-414.
              </li>
              <li>
                Li W., Lee M. S. Dried blood spots: applications and techniques.
                Wiley Series on Pharmaceutical Science and Biotechnology:
                Practices, Applications and Methods, 2014. 363 p.
              </li>
              <li>
                Lehmann S., Picas A., Tiers L., Vialaret J., Hirtz C. Clinical
                perspectives of dried blood spot protein quantification using
                mass spectrometry methods. Crit. Rev. Clin. Lab. Sci, 2017. Vol.
                54, № 3. P. 173–184.
              </li>
              <li>
                Freeman J. D., Rosman L. M., Ratcliff J. D., Strickland P. T.,
                Graham D. R., Silbergeld E. K. State of the science in dried
                blood spots. Clin. Chem, 2018. Vol. 64, № 4. P. 656–679.
              </li>
              <li>
                Clinical and Laboratory Standards Institute (2007): Blood
                Collection on Filter Paper for Newborn Screening Programs
                Approved Standard — Fifth edition, CLSI Document LA4 — A5, CLSI,
                Wayne, Pennsylvania, 19087–1898, Vol. 27, № 20.
              </li>
              <li>
                Uribe, R. Giugliani Selective Screening for Lysosomal Storage
                Diseases with Dried Blood Spots Collected on Filter Paper in
                4,700 High-Risk Colombian Subjects JIMD Rep, 2013; 11: 107–116.
              </li>
              <li>
                Yu C., Sun Q.,H. Zhou Enzymatic Screening and Diagnosis of
                Lysosomal Storage Diseases N Am J Med Sci (Boston). 2013; 6(4):
                186–193.
              </li>
              <li>
                Приказ Министерства здравоохранения и социального развития РФ от
                22 марта 2006 г. N 185 «О массовом обследовании новорожденных
                детей на наследственные болезни».
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MSPK",
  mounted() {
    document.querySelector(".main-wrapper").style.background =
      "linear-gradient(180deg, #FFFFFF 0%, #E5EAF8 100%)";
  },
  beforeDestroy() {
    document.querySelector(".main-wrapper").style.background = "";
  },
};
</script>

<style lang="scss" scoped>
.mspk {
  & * {
    font-family: "Manrope", sans-serif !important;
  }
  &__first-banner {
    margin-top: 32px;

    & .content {
      height: 100%;
    }

    &-img {
      height: 100%;
      width: 100%;
      aspect-ratio: 2 / 1.06;
      background-image: url("https://storage.yandexcloud.net/"+$VUE_APP_BACKET_NAME+"/media/content/public/assets/img/mspk/banner.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      // height: 640px;
      padding: 60px 70px;
      display: flex;
      flex-direction: column;
      border-radius: 32px;
      overflow: hidden;
      @media screen and (max-width: 1220px) {
        padding: 32px 24px;
      }

      @media screen and (max-width: 767px) {
        aspect-ratio: auto;
        background-image: url("https://storage.yandexcloud.net/"+$VUE_APP_BACKET_NAME+"/media/content/public/assets/img/mspk/banner_mob.jpg");
        height: 685px;
      }
    }

    &-title {
      margin-top: auto;
      font-weight: 500;
      font-size: 60px;
      line-height: 120%;
      color: #414b51;

      & span {
        color: #fe8271;
      }

      & sup {
        vertical-align: super;
        font-size: 32px;
      }

      @media screen and (max-width: 1220px) {
        font-size: 32px;
      }

      @media screen and (max-width: 767px) {
        margin-top: 40px;
        & sup {
          font-size: 24px;
        }
      }
    }
  }

  &__about {
    padding-top: 120px;
    @media screen and (max-width: 1220px) {
      padding-top: 80px;
    }
    @media screen and (max-width: 767px) {
      padding-top: 40px;
    }
    &-row {
      display: grid;
      grid-template-columns: 163px 1fr;
      column-gap: 135px;

      @media screen and (max-width: 1220px) {
        grid-template-columns: 1fr;
        row-gap: 32px;
      }

      @media screen and (max-width: 767px) {
        row-gap: 24px;
      }
    }

    &-tag {
      cursor: default;
      background-color: #fe8271;
      color: #fff;
      font-size: 20px;
      padding: 10px 24px;
      height: fit-content;
      width: fit-content !important;
      &::before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: currentColor;
        display: block;
        margin-right: 8px;
        flex-shrink: 0;
      }
    }

    &-text {
      font-weight: 500;
      font-size: 24px;
      line-height: 140%;
      color: #414b51;

      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
  }

  &__instruction {
    padding-top: 120px;
    @media screen and (max-width: 1220px) {
      padding-top: 80px;
    }
    @media screen and (max-width: 767px) {
      padding-top: 40px;
    }

    &-row {
      display: grid;
      grid-template-columns: 394px 640px;
      column-gap: 60px;

      @media screen and (max-width: 1220px) {
        grid-template-columns: 1fr;
        row-gap: 32px;
      }
    }

    &-video {
      &-img {
        cursor: pointer;
        & img {
          width: 100%;
        }
      }

      &-title {
        margin-top: 20px;
        font-weight: 600;
        font-size: 28px;
        line-height: 120%;
        text-align: center;
        color: #414b51;

        @media screen and (max-width: 767px) {
          font-size: 24px;
        }
      }
    }

    &-block {
      padding: 45px;
      display: flex;
      flex-direction: column;
      height: 394px;
      border-radius: 32px;
      box-shadow: 0px 1px 4px rgba(14, 31, 53, 0.12);
      background-image: url("https://storage.yandexcloud.net/"+$VUE_APP_BACKET_NAME+"/media/content/public/assets/img/mspk/instruction-back.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      @media screen and (max-width: 767px) {
        padding: 45px 24px;
      }

      &-title {
        font-weight: 600;
        font-size: 32px;
        line-height: 120%;
        color: #414b51;

        @media screen and (max-width: 767px) {
          font-size: 24px;
        }
      }

      &-button {
        margin-top: auto;
        background-color: #fe8271;
        color: #fff;
        font-size: 20px;
        padding: 16px 24px;
        border-radius: 10px;
        box-shadow: 0px 11px 45px -13px #fe8271;

        @media screen and (max-width: 767px) {
          font-size: 16px;
        }
      }
    }
  }

  &__description {
    padding-top: 120px;
    @media screen and (max-width: 1220px) {
      padding-top: 80px;
    }
    @media screen and (max-width: 767px) {
      padding-top: 40px;
    }
    &-caption-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 60px;

      @media screen and (max-width: 1220px) {
        grid-template-columns: 1fr;
        row-gap: 32px;
      }
    }

    &-title {
      font-weight: 500;
      font-size: 40px;
      line-height: 130%;
      color: #414b51;

      @media screen and (max-width: 767px) {
        font-size: 24px;
      }
    }

    &-description {
      font-weight: 500;
      font-size: 20px;
      line-height: 140%;
      color: #414b51;

      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }

    &-row {
      margin-top: 16px;
      display: grid;
      grid-template-columns: 0.6fr 0.4fr;
      column-gap: 20px;
      align-items: flex-end;

      @media screen and (max-width: 1220px) {
        margin-top: 32px;
        grid-template-columns: 1fr;
        row-gap: 32px;
      }
    }

    &-text {
      padding: 40px;
      border-radius: 32px;
      background-color: #e5eaf8;
      height: 89%;

      @media screen and (max-width: 1220px) {
        height: auto;
      }

      @media screen and (max-width: 767px) {
        padding: 20px;
        border-radius: 20px;
      }

      &-title {
        margin-bottom: 16px;
        font-weight: 600;
        font-size: 28px;
        line-height: 120%;
        color: #414b51;

        @media screen and (max-width: 767px) {
          font-size: 24px;
        }
      }

      &-description {
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        color: #414b51;
      }
    }

    &-img {
      @media screen and (max-width: 1220px) {
        margin-top: -11%;
      }
      @media screen and (max-width: 767px) {
        margin-top: -12%;
      }
      img {
        width: 100%;
      }
    }
  }

  &__methods {
    padding-top: 120px;
    @media screen and (max-width: 1220px) {
      padding-top: 80px;
    }
    @media screen and (max-width: 767px) {
      padding-top: 40px;
    }
    &-caption-row {
      display: grid;
      grid-template-columns: 1.1fr 0.9fr;
      column-gap: 48px;
      @media screen and (max-width: 1220px) {
        grid-template-columns: 1fr;
        row-gap: 32px;
      }
    }

    &-title {
      font-weight: 500;
      font-size: 40px;
      line-height: 130%;
      color: #414b51;

      @media screen and (max-width: 767px) {
        font-size: 24px;
      }
    }

    &-description {
      font-weight: 500;
      font-size: 20px;
      line-height: 140%;
      color: #414b51;

      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }

    &-row-title {
      margin: 60px 0;
      font-weight: 600;
      font-size: 28px;
      line-height: 120%;
      text-align: center;
      color: #414b51;

      @media screen and (max-width: 767px) {
        font-size: 24px;
        text-align: left;
      }

      & span {
        color: #f5614d;
      }
    }

    &-row {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      column-gap: 20px;
      row-gap: 40px;
      align-items: flex-end;
      @media screen and (max-width: 1220px) {
        grid-template-columns: 1fr;
        gap: 32px;
      }

      &-item {
        padding: 24px 16px;
        display: flex;
        flex-direction: column;
        background-size: 100% auto;
        background-position: top left;
        background-color: #e5eaf8;
        background-repeat: no-repeat;
        border-radius: 20px;
        height: 100%;
        @media screen and (max-width: 767px) {
          background-size: 100% auto;
        }
        &:nth-child(1) {
          grid-column-start: 1;
          grid-column-end: 3;
          @media screen and (max-width: 1220px) {
            grid-column-start: auto;
            grid-column-end: auto;
          }
        }
        &:nth-child(2) {
          grid-column-start: 3;
          grid-column-end: 5;
          height: 80%;
          @media screen and (max-width: 1220px) {
            grid-column-start: auto;
            grid-column-end: auto;
            height: 100%;
          }
        }
        &:nth-child(3) {
          grid-column-start: 5;
          grid-column-end: 7;
          height: 60%;
          @media screen and (max-width: 1220px) {
            grid-column-start: auto;
            grid-column-end: auto;
            height: 100%;
          }
        }
        &:nth-child(4) {
          grid-column-start: 1;
          grid-column-end: 4;
          @media screen and (max-width: 1220px) {
            grid-column-start: auto;
            grid-column-end: auto;
          }
        }
        &:nth-child(5) {
          grid-column-start: 4;
          grid-column-end: 7;
          @media screen and (max-width: 1220px) {
            grid-column-start: auto;
            grid-column-end: auto;
          }
        }

        &-head {
          margin-bottom: 16px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          @media screen and (max-width: 1220px) {
            flex-direction: column;
            row-gap: 16px;
          }
        }

        &-img {
          margin-left: auto;
          margin-bottom: 30px;
          @media screen and (max-width: 1220px) {
            margin-left: 0;
          }
          & img {
            max-width: 100%;
          }
        }

        &-number {
          margin-top: 10px;
        }

        &-text {
          margin-top: auto;
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          color: #414b51;

          & b {
            font-weight: 600;
          }
        }
      }
    }
  }

  &__diseases {
    padding-top: 120px;
    @media screen and (max-width: 1220px) {
      padding-top: 80px;
    }
    @media screen and (max-width: 767px) {
      padding-top: 40px;
    }

    &-title {
      margin-bottom: 60px;
      font-weight: 500;
      font-size: 40px;
      line-height: 130%;
      color: #414b51;

      @media screen and (max-width: 767px) {
        font-size: 24px;
      }
    }

    &-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 110px;

      @media screen and (max-width: 1220px) {
        grid-template-columns: 1fr;
        gap: 32px;
      }
    }

    &-item {
      &-img {
        margin-bottom: 16px;
      }

      &-title {
        margin-bottom: 8px;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 140%;
        color: #f5614d;
      }

      &-description {
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
        color: #414b51;
      }
    }
  }

  &__order {
    position: relative;
    padding-top: 120px;
    @media screen and (max-width: 1220px) {
      padding-top: 80px;
    }
    @media screen and (max-width: 767px) {
      padding-top: 40px;
    }
    &::before {
      width: 0px;
      height: 0px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      right: 0;
      box-shadow: 0px 0px 9900px 300px #fe8271;
      content: "";
      display: block;
      filter: blur(464px);
    }

    &-title {
      margin-bottom: 60px;
      font-weight: 500;
      font-size: 40px;
      line-height: 130%;
      color: #414b51;

      @media screen and (max-width: 767px) {
        font-size: 24px;
      }
    }

    &-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 40px;

      @media screen and (max-width: 1220px) {
        grid-template-columns: 1fr;
        gap: 24px;
      }
    }

    &-col {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
    }

    &-item {
      &-head {
        margin-bottom: 16px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      &-number {
        margin-right: 16px;
        flex-shrink: 0;
      }

      &-title {
        font-weight: 600;
        font-size: 24px;
        line-height: 140%;
        color: #414b51;

        @media screen and (max-width: 767px) {
          font-size: 20px;
        }
      }

      &-text {
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        color: #414b51;

        &-link {
          text-decoration: underline;
          color: #fe8271;
          font-weight: 700;
        }

        &-number {
          font-size: 20px;
          color: #fe8271;
          font-weight: 700;
        }

        & b {
          color: #fe8271;
          font-weight: 700;
        }

        & img {
          max-width: 100%;
        }
      }
    }
  }

  &__sources {
    padding-top: 120px;
    @media screen and (max-width: 1220px) {
      padding-top: 80px;
    }
    @media screen and (max-width: 767px) {
      padding-top: 40px;
    }
    &-block {
      border-radius: 32px;
      background-color: #565e75;
      padding: 40px;
      color: #fff;
      @media screen and (max-width: 767px) {
      padding-top: 40px 20px;
    }
    }

    &-title {
      margin-bottom: 16px;
      font-weight: 600;
      font-size: 24px;
      line-height: 140%;
    }

    &-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;

      & ol {
        list-style-type: decimal;
        list-style-position: inside;

        & li {
          &:not(:last-child) {
            margin-bottom: 4px;
          }
        }
      }
    }
  }
}
</style>